var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "cursor-pointer scroll-container d-flex flex-row overflow-auto gap-3 px-1",
    attrs: {
      "no-body": ""
    }
  }, [_vm._l(_vm.trains, function (train, indexTrain) {
    return [_c('div', {
      key: "train-".concat(train.id),
      class: "my-75 p-1 rounded-lg rounded-lg shadow text-nowrap d-flex flex-column justify-content-between\n      ".concat(_vm.isSelectedTrain(train.id) ? 'border-success-2' : 'border-light-2', "\n      "),
      on: {
        "click": function click($event) {
          return _vm.selectTrainHandle(train);
        }
      }
    }, [_c('div', [_c('div', {
      staticClass: "text-center fw-800 text-info mb-50"
    }, [_vm._v(" " + _vm._s(train.trainCode) + " ")]), _c('div', {
      staticClass: "d-flex-between mb-50"
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_vm._v(" Thời gian đi ")]), _c('span', {
      staticClass: "fw-800"
    }, [_vm._v(" " + _vm._s(train.dateTimeDeparture) + " ")])]), _c('div', {
      staticClass: "d-flex-between mb-50"
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_vm._v(" Thời gian đến ")]), _c('span', {
      staticClass: "fw-800"
    }, [_vm._v(" " + _vm._s(train.dateTimeArrival) + " ")])]), _c('div', {
      staticClass: "d-flex-between mb-50"
    }, [_c('span', {
      staticClass: "mr-3"
    }, [_vm._v(" Đã đặt "), _c('span', {
      staticClass: "fw-700"
    }, [_vm._v("0 chỗ")])]), _c('span', [_vm._v(" Còn trống "), _c('span', {
      staticClass: "fw-700 text-success"
    }, [_vm._v(_vm._s(train.totalSeatCanPick) + " chỗ")])])])]), _c('div', {
      staticStyle: {
        "height": "170px"
      }
    }, [_c('b-img', {
      staticClass: "full-height-img",
      attrs: {
        "src": _vm.isSelectedTrain(train.id) ? require("@train/assets/train_font_active.svg") : require("@train/assets/train_font_default.svg"),
        "alt": "Train",
        "fluid": "",
        "center": ""
      }
    })], 1)])];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }