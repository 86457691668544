<template>
  <b-card
    class="cursor-pointer scroll-container d-flex flex-row overflow-auto gap-3 px-1"
    no-body
  >
    <template v-for="(train, indexTrain) of trains">
      <div
        :key="`train-${train.id}`"
        :class="`my-75 p-1 rounded-lg rounded-lg shadow text-nowrap d-flex flex-column justify-content-between
        ${isSelectedTrain(train.id) ? 'border-success-2' : 'border-light-2'}
        `"
        @click="selectTrainHandle(train)"
      >
        <div>
          <div class="text-center fw-800 text-info mb-50">
            {{ train.trainCode }}
          </div>
          <div class="d-flex-between mb-50">
            <span class="mr-2">
              Thời gian đi
            </span>
            <span class="fw-800">
              {{ train.dateTimeDeparture }}
            </span>
          </div>
          <div class="d-flex-between mb-50">
            <span class="mr-2">
              Thời gian đến
            </span>
            <span class="fw-800">
              {{ train.dateTimeArrival }}
            </span>
          </div>
          <div class="d-flex-between mb-50">
            <span class="mr-3">
              Đã đặt <span class="fw-700">0 chỗ</span>
            </span>
            <span>
              Còn trống <span class="fw-700 text-success">{{ train.totalSeatCanPick }} chỗ</span>
            </span>
          </div>
        </div>

        <div style="height: 170px">
          <b-img
            :src="isSelectedTrain(train.id) ? require(`@train/assets/train_font_active.svg`) : require(`@train/assets/train_font_default.svg`)"
            alt="Train"
            fluid
            center
            class="full-height-img"
          />
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BCard, BImgLazy, BImg,
} from 'bootstrap-vue'

import store from '@/store'

import useTrainHandle from '@train/useTrainHandle'

export default {
  components: {
    BCard,
    BImgLazy,
    BImg,
  },
  props: {
    itineraryIndex: {
      type: Number,
      required: true,
    },
    trains: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const {
      loading,
      stateTrainSelectedArray,
    } = useTrainHandle()

    const isSelectedTrain = id => stateTrainSelectedArray.value.some(train => train.id === id)

    function selectTrainHandle(train) {
      store.commit('app-train/SET_SELECTED_TRAIN', train)
    }

    return {
      stateTrainSelectedArray,
      selectTrainHandle,
      isSelectedTrain,
    }
  },
}
</script>

<style lang="scss">
/* Custom scrollbar cho Chrome, Safari */
.scroll-container::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Custom scrollbar cho Firefox */
.scroll-container {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.full-height-img {
  height: 100%; /* Đặt chiều cao của b-img bằng 100% của thẻ div */
  width: auto;  /* Đảm bảo tỷ lệ không bị thay đổi */
}
</style>
